import service from '@/middleware'

const stoppedWorkFrontsApi = {
  // todos los dos gráficos
  async getStoppedWorkFrontsAll (sector, types, percentage, from, to, timeRange, activities) {
    const activityId = activities[0].id
    const body = {
      sector,
      types,
      percentage,
      from,
      to,
      timeRange,
      activityId
    }
    const result = await service.http.post('/api/stopped-work-fronts/all', body)
    return result
  }
}

export default stoppedWorkFrontsApi
