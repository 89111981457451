import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue'
import i18n from '@/i18n'
import Vue from 'vue'
import { TimeRanges, CustomTimeRangeTypes, Dataset } from '@colven/common-domain-lib/lib'
import activityApi from '@/api/activity.api'
import enterpriseApi from '@/api/enterprise.api'
import stoppedWorkFrontsApi from '@/api/stoppedWorkFronts.api'
import thingApi from '@/api/thing.api'
import { selectorDateTimeValidation, getDateTimeRange, getTimeRangeNames } from '@/tools/functions'
import { configurationService } from '@/business/configurationService'

// constante de update de gráficos (en minutos)
const REFRESH = 10

export default {
  name: 'StoppedWorkFrontsComponent',
  components: {
    DashboardChartContainer
  },
  props: {
    chartComponentId: {
      type: String,
      required: true
    },
    sector: {
      type: String,
      required: false,
      default: () => { return null }
    }
  },
  data: () => ({
    report: 'STOPPED_WORK_FRONTS',
    chartContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      // rangos de tiempo
      dateAndTimeRanges: [
        // inicio de zafra, jornada diaria, personalizado
        // {
        //   id: TimeRanges.HARVEST_START,
        //   text: i18n.t('selector.dateAndTime.harvestStart'),
        //   custom: false
        // },
        {
          id: TimeRanges.LAST_HOUR,
          text: i18n.t('selector.dateAndTime.lastHour'),
          custom: false
        },
        {
          id: TimeRanges.LAST_SIX_HOUR,
          text: i18n.t('selector.dateAndTime.lastSixHours'),
          custom: false
        },
        {
          id: TimeRanges.TODAY,
          text: i18n.t('selector.dateAndTime.today'),
          custom: false
        },
        {
          id: TimeRanges.YESTERDAY,
          text: i18n.t('selector.dateAndTime.yesterday'),
          custom: false
        },
        {
          id: TimeRanges.LAST_WEEK,
          text: i18n.t('selector.dateAndTime.lastWeek'),
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE,
          text: i18n.t('selector.dateAndTime.dailyShift'),
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE_PREVIOUS,
          text: i18n.t('selector.dateAndTime.previousShift'),
          custom: false
        }
        /*
        {
          id: TimeRanges.CUSTOM,
          text: i18n.t('selector.dateAndTime.custom'),
          custom: true
        }
        */
      ],
      //  tipos de rangos personalizados
      dateAndTimeCustomRangeTypes: [
        {
          text: i18n.t('selector.dateAndTime.customTypes.dateAndTime'),
          value: CustomTimeRangeTypes.DATE_AND_TIME
        },
        {
          text: i18n.t('selector.dateAndTime.customTypes.date'),
          value: CustomTimeRangeTypes.DATE
        }
      ],
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      customDateTimeValidForm: false,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      filters: [
        {
          id: 'activity',
          name: i18n.t('headers.activity'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        {
          id: 'type',
          name: i18n.t('headers.thingType'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      percentageSwitchModel: false,
      selectorData: [],
      selectorModel: null,
      charts: [],
      disableSaveButton: false,
      update: false
    },
    // datos de los filtrso seleccionados
    selectedDateAndTimeRange: null,
    selectedDateAndTimeRangeCustomType: null,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    timestampFrom: null,
    timestampTo: null,
    types: [],
    activities: [],
    // arreglo de arreglos de datos (fincas, frentes de trabajo, máquinas, rrhh)
    selectorsData: [[], [], [], []],
    // arreeglo de los valores seleccionados
    selectorsModel: [null, null, null, null],
    currentChartIndex: 0,
    loading: false,
    chartName: '',
    intervalId: null,
    // para guardar los datos del dashboard
    data: [],
    // para la opción "todos" de los selectores
    all: {
      id: 'ALL',
      name: i18n.t('all')
    }
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    let initialLoad = true
    this.loading = true

    // actividades
    const activityResponse = await activityApi.getAllForSelector(this.sector, this.report)
    if (Array.isArray(activityResponse.data)) {
      this.chartContainerModel.filters[0].data.cleanAndUpdate(activityResponse.data)
      if (activityResponse.data.length === 1 && activityResponse.data[0].key === 'NO_ACTIVITY') {
        this.chartContainerModel.filters[0].selectedData = this.chartContainerModel.filters[0].data.filter(s => s.key === 'NO_ACTIVITY')
        this.chartContainerModel.filters[0].disabled = true
        this.chartContainerModel.filters[0].hide = true
        await this.activityFilterCallback()
      }
    }

    // llamado a callback de actividades
    this.chartContainerModel.filters[0].selectAction = this.activityFilterCallback.bind(this)

    // fecha personaliza solo para los superadmin
    // PAGRO-2308 SE DESHABILITAN PARA TODOS LOS USERS
    /*
    let admin = JSON.parse(localStorage.getItem('admin'))
    if (admin) {
      this.chartContainerModel.dateAndTimeRanges.push({
        id: TimeRanges.CUSTOM,
        text: i18n.t('selector.dateAndTime.custom'),
        custom: true
      })
      this.chartContainerModel.dateAndTimeRanges.push({
        id: TimeRanges.HARVEST_START,
        text: i18n.t('selector.dateAndTime.harvestStart'),
        custom: false
      })
    }
    */

    // tiempo de jornada diaria
    if (!JSON.parse(localStorage.getItem('enterpriseTimeSpan'))) {
      const enterpriseTimeSpan = (await enterpriseApi.getEnterpriseCustomTimeSpan()).data
      if (enterpriseTimeSpan) {
        localStorage.setItem('enterpriseTimeSpan', JSON.stringify(enterpriseTimeSpan))
      } else {
        this.chartContainerModel.dateAndTimeRanges.splice(this.chartContainerModel.dateAndTimeRanges.indexOf(x => x.id === TimeRanges.ENTERPRISE) - 1, 1)
      }
    }

    // tiempo de inicio de zafra
    if (!JSON.parse(localStorage.getItem('harvestStartTime'))) {
      const harvestStartTime = (await enterpriseApi.getEnterpriseHarvestStartTime()).data
      if (harvestStartTime) {
        localStorage.setItem('harvestStartTime', JSON.stringify(harvestStartTime))
      } else {
        const havevestStartIndex = this.chartContainerModel.dateAndTimeRanges.indexOf(x => x.id === TimeRanges.HARVEST_START)
        if (havevestStartIndex !== -1) {
          this.chartContainerModel.dateAndTimeRanges.splice(havevestStartIndex - 1, 1)
          initialLoad = false
        }
      }
    }

    // configuración
    initialLoad = await this.getConfiguration(initialLoad)

    this.loading = false
    // ejecuto la validación del selector, si es correcta, se carga el gráfico, sino abro el selector
    if (initialLoad) {
      // creo los gráficos
      this.getData(this.chartContainerModel)
    } else {
      this.chartContainerModel.showNavigationPanel = true
    }
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  destroyed () {
  },
  computed: {
    /**
     * Detalles del gráfico (html)
     */
    details () {
      const timeNames = getTimeRangeNames()
      const activities = this.activities.map(a => a.name).toString()
      const types = this.types.map(t => t.name).toString()
      let details = `<h3>${this.$t('chart.chart')}: ${this.$t('dashboard.stoppedWorkFronts.title')}</h3>`
      if (this.chartName) {
        details += `<h5>${this.$t('level')}: ${this.chartName}</h5>`
      }
      if (this.chartContainerModel.filters && this.chartContainerModel.filters[0].data[0] && this.chartContainerModel.filters[0].data[0].key !== 'NO_ACTIVITY') {
        details += `<h5>${this.$t('activity')}: ${activities}</h5>`
      }
      if (this.chartContainerModel.filters && this.chartContainerModel.filters[1].data[0]) {
        details += `<h5>${this.$t('type')}: ${types}</h5>`
      }
      details += `<h5>${this.$t('selector.dateAndTime.label')}: ${timeNames[this.selectedDateAndTimeRange]}</h5>`
      return details
    }
  },
  methods: {
    async getData (filters, update = false) {
      configurationService.save(this.chartComponentId,
        {
          activities: filters.filters[0].selectedData,
          types: filters.filters[1].selectedData,
          selectedDateAndTimeRange: filters.selectedDateAndTimeRange,
          selectedDateAndTimeRangeCustomType: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.selectedDateAndTimeRangeCustomType : null,
          sinceDate: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.sinceDate : null,
          sinceTime: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.sinceTime : null,
          toDate: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.toDate : null,
          toTime: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.toTime : null
        })
      clearInterval(this.intervalId)
      await this.getDashboardData(filters, update)
      if (filters.selectedDateAndTimeRange !== TimeRanges.CUSTOM) {
        const that = this
        that.intervalId = setInterval(async function () {
          await that.updateAllCharts()
        }, REFRESH * 60 * 1000)
      }
    },
    async getDashboardData (filters, update) {
      this.loading = true

      // se guardan los datos del rango de tiempo seleccionado
      this.selectedDateAndTimeRange = filters.selectedDateAndTimeRange
      this.selectedDateAndTimeRangeCustomType = filters.selectedDateAndTimeRangeCustomType
      this.sinceDate = filters.sinceDate
      this.sinceTime = filters.sinceTime
      this.toDate = filters.toDate
      this.toTime = filters.toTime

      // conversion del rango de tiempo seleccionado
      const conversion = getDateTimeRange(filters.selectedDateAndTimeRange, filters.selectedDateAndTimeRangeCustomType,
        filters.sinceDate, filters.sinceTime, filters.toDate, filters.toTime)
      this.timestampFrom = conversion.tFrom
      this.timestampTo = conversion.tTo

      // se guardan los datos de la actividad seleccionada
      this.activities = Array.from(filters.filters[0].selectedData)

      // se guardan los tipos de máquina según la actividad
      this.types = Array.from(filters.filters[1].selectedData)
      const typesKey = this.types.map(t => t.key)

      // datos para el dashboard
      const dashboardResponse = await stoppedWorkFrontsApi.getStoppedWorkFrontsAll(this.sector, typesKey,
        this.chartContainerModel.percentageSwitchModel, this.timestampFrom, this.timestampTo, this.selectedDateAndTimeRange, this.activities)

      // actualizo los datos del gráfico y guardo los datasets
      this.chartContainerModel.charts.cleanAndUpdate(dashboardResponse.data.charts)
      this.data = dashboardResponse.data.data

      // actualizo la lista de datos para los selectores
      this.selectorsData[0].cleanAndUpdate([this.all].concat(dashboardResponse.data.farms))
      this.selectorsData[1].cleanAndUpdate([this.all].concat(dashboardResponse.data.workFronts))
      this.selectorsData[2].cleanAndUpdate([this.all].concat(dashboardResponse.data.machines))
      this.selectorsData[3].cleanAndUpdate([this.all].concat(dashboardResponse.data.rrhhs))

      // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
      this.selectorsModel.forEach((model, index) => {
        if (!this.selectorsData[index].find(item => item.id === model)) {
          model = null
        }
      })

      if (update) {
        // MODIFICACIÓN DE LOS FILTROS

        // si es una actualización (se modificaron los filtros, actualizo los datos del selector actual)
        this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[this.currentChartIndex])
        this.chartContainerModel.selectorModel = this.selectorsModel[this.currentChartIndex]

        // update
        this.chartContainerModel.update = true
        const that = this
        Vue.nextTick(function () {
          that.chartContainerModel.update = false
        })
      } else {
        // PRIMERA CARGA DE LOS GRÁFICOS

        this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[0])
        this.chartName = this.chartContainerModel.charts[0].name
      }
      this.loading = false
    },
    /**
     * Cuando se cambia el switch de porcentaje, se ejecuta esta función
     * para cambiar los datasets y otras configs del gráfico
     */
    switchPercentage (percentage) {
      this.chartContainerModel.charts.forEach((chart) => {
        chart.timeDurationY = !percentage
        chart.options.scales.yAxes[0].scaleLabel.labelString = percentage
          ? this.$t('dashboard.percentage')
          : this.$t('dashboard.time')
      })
      this.filterCurrentChart(this.currentChartIndex, percentage)
    },
    /**
     * Para filtrar los datos del gráfico de acuerdo al selector
     * @param {*} index
     * @param {*} percentage
     */
    filterCurrentChart (index, percentage) {
      if (!this.chartContainerModel.selectorModel || this.chartContainerModel.selectorModel === 'ALL') {
        const { labels, datasets } = this.generateDatasets(percentage, null)
        this.chartContainerModel.charts[index].data.datasets = datasets
        this.chartContainerModel.charts[index].data.labels = labels
      } else {
        const { labels, datasets } = this.generateDatasets(percentage, this.chartContainerModel.selectorModel)
        this.chartContainerModel.charts[index].data.datasets = datasets
        this.chartContainerModel.charts[index].data.labels = labels
      }

      /*
        SOLUCIÓN PROVISORIA PARA LA ACTUALIZACIÓN DE GRÁFICOS:
        CUANDO SE IMPLEMETE EL MODELO EN EL COMPONENTE GENÉRICO, LA ACTUALIZACIÓN SE REALIZARÁ DE FORMA INMEDIATA
        CUANDO CAMBIEN LOS DATOS DEL GRÁFICO.
        Por ahora se definió un variable booleana del tipo "semáforo", es decir, cuando está en true, ejecuta la actualización
      */
      this.chartContainerModel.update = true
      const that = this
      Vue.nextTick(function () {
        that.chartContainerModel.update = false
      })
    },
    /**
     * Para generar los datasets cuando se filtra o se cambia de modo (porcentaje / tiempo)
     * @param {*} percentage
     * @param {*} id
     */
    generateDatasets (percentage, id) {
      const labels = []
      // datasets
      const datasetParada = new Dataset()
      datasetParada.label = this.$t('dashboard.stoppedWorkFronts.stopped')
      datasetParada.backgroundColor = '#f44336'
      datasetParada.borderColor = '#f44336'
      const datasetInefficiency = new Dataset()
      datasetInefficiency.label = this.$t('dashboard.stoppedWorkFronts.inefficiency')
      datasetInefficiency.backgroundColor = '#2196F3'
      datasetInefficiency.borderColor = '#2196F3'
      const datasetSlack = new Dataset()
      datasetSlack.label = this.$t('dashboard.stoppedWorkFronts.lackOfEmptiness')
      datasetSlack.backgroundColor = '#FFEB3B'
      datasetSlack.borderColor = '#FFEB3B'
      const filteredData = id
        ? this.data[this.currentChartIndex].filter(d => d.id === id)
        : this.data[this.currentChartIndex]
      filteredData.forEach(element => {
        if (percentage) {
          datasetParada.data.push(element.paradaPercentage.value)
          datasetInefficiency.data.push(element.inefficiencyPercentage.value)
          datasetSlack.data.push(element.faltaVacioPercentage.value)
          // custom tooltips
          datasetParada.formattedTooltipData.label.push(element.paradaPercentage.text)
          datasetParada.formattedLabelData.push(element.paradaPercentage.text)
          datasetInefficiency.formattedTooltipData.label.push(element.inefficiencyPercentage.text)
          datasetInefficiency.formattedLabelData.push(element.inefficiencyPercentage.text)
          datasetSlack.formattedTooltipData.label.push(element.faltaVacioPercentage.text)
          datasetSlack.formattedLabelData.push(element.faltaVacioPercentage.text)
        } else {
          datasetParada.data.push(element.paradaTime.value)
          datasetInefficiency.data.push(element.inefficiency.value)
          datasetSlack.data.push(element.faltaVacioTime.value)
          // custom tooltips
          datasetParada.formattedTooltipData.label.push(element.paradaTime.text)
          datasetParada.formattedLabelData.push(element.paradaTime.text)
          datasetInefficiency.formattedTooltipData.label.push(element.inefficiency.text)
          datasetInefficiency.formattedLabelData.push(element.inefficiency.text)
          datasetSlack.formattedTooltipData.label.push(element.faltaVacioTime.text)
          datasetSlack.formattedLabelData.push(element.faltaVacioTime.text)
        }
        labels.push(element.name)
      })
      return { labels, datasets: [datasetParada, datasetInefficiency, datasetSlack] }
    },
    /**
     * Función ejecutada cuando se cambia de tab (se cambia de gráfico)
     * @param {*} data
     */
    changeChart (data) {
      if (data.index !== this.currentChartIndex) {
        // nombre del gráfico (para los detalles)
        this.chartName = this.chartContainerModel.charts[data.index].name
        // cambio el valor del selector y guardo el valor para el gráfico actual
        this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[data.index])
        // currentChart aún tiene los valores del gráfico anterior, guardo el valor
        this.selectorsModel[this.currentChartIndex] = this.chartContainerModel.selectorModel
        // actualizo el valor de currentChart
        this.currentChartIndex = data.index
        // actualización del gráfico
        if (this.chartContainerModel.selectorModel !== this.selectorsModel[data.index]) {
          // si hay valor seleccionado, lo seteo
          this.chartContainerModel.selectorModel = this.selectorsModel[data.index]
        } else {
          this.updateChart()
        }
      }
    },
    /**
     * Para actualizar el gráfico actual cuando se usa el selector
     */
    async updateChart () {
      this.loading = true

      // filtra los datos del gráfico actual de acuerdo a la selección
      this.filterCurrentChart(this.currentChartIndex, this.chartContainerModel.percentageSwitchModel)

      this.loading = false
    },
    /**
     * Este método es ejecutado cuando se actualizan todos los gráficos por el timer del "autoreporte"
     */
    async updateAllCharts () {
      this.loading = true
      // primero, actualizo los valores de los timestamps
      const conversion = getDateTimeRange(this.selectedDateAndTimeRange, this.selectedDateAndTimeRangeCustomType,
        this.sinceDate, this.sinceTime, this.toDate, this.toTime)
      this.timestampFrom = conversion.tFrom
      this.timestampTo = conversion.tTo

      // GENERO LOS DATOS NUEVAMENTE
      const dashboardResponse = await stoppedWorkFrontsApi.getStoppedWorkFrontsAll(this.sector, this.types, this.chartContainerModel.percentageSwitchModel, this.timestampFrom, this.timestampTo, this.selectedDateAndTimeRange, this.activities)

      // datos
      this.data = dashboardResponse.data.data

      // actualizo los títulos
      const title = dashboardResponse.data.title
      if (title != null) {
        this.chartContainerModel.charts.forEach((chart) => {
          chart.options.title.text = title
        })
      }

      // actualizo la lista de datos para los selectores
      this.selectorsData[0].cleanAndUpdate([this.all].concat(dashboardResponse.data.farms))
      this.selectorsData[1].cleanAndUpdate([this.all].concat(dashboardResponse.data.workFronts))
      this.selectorsData[2].cleanAndUpdate([this.all].concat(dashboardResponse.data.machines))
      this.selectorsData[3].cleanAndUpdate([this.all].concat(dashboardResponse.data.rrhhs))

      // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
      this.selectorsModel.forEach((model, index) => {
        if (!this.selectorsData[index].find(item => item.id === model)) {
          model = null
        }
      })

      // filtra los datos del gráfico actual de acuerdo a la selección
      this.filterCurrentChart(this.currentChartIndex, this.chartContainerModel.percentageSwitchModel)

      this.loading = false
    },
    /**
     * Para validar el selector de filtros y habilitar/deshabilitar el botón para guardar
     */
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const activitySelected = that.chartContainerModel.filters[0].selectedData
        const typeSelected = that.chartContainerModel.filters[1].selectedData
        const customTimeValidation = selectorDateTimeValidation(that.chartContainerModel.selectedDateAndTimeRange,
          that.chartContainerModel.selectedDateAndTimeRangeCustomType, that.chartContainerModel.customDateTimeValidForm,
          that.chartContainerModel.sinceDate, that.chartContainerModel.sinceTime, that.chartContainerModel.toDate, that.chartContainerModel.toTime)
        that.chartContainerModel.disableSaveButton =
        activitySelected.length === 0 || typeSelected.length === 0 || customTimeValidation
      })
    },
    async activityFilterCallback () {
      if (this.chartContainerModel.filters[0].selectedData.length > 0) {
        this.chartContainerModel.filters[1].selectedData = []
        this.chartContainerModel.filters[1].disabled = false
        const thingTypesResponse =
          await thingApi.getThingTypesByActivity(this.chartContainerModel.filters[0].selectedData.map(a => a.id), this.sector, this.report)
        this.chartContainerModel.filters[1].data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.chartContainerModel.filters[1].selectedData = []
        this.chartContainerModel.filters[1].disabled = true
      }
    },
    /**
     * Para obtener la configuración de filtros
     * Esta función se ejecuta en mounted
     * @param {*} init
     * @returns
     */
    async getConfiguration (init) {
      let initialLoad = init
      const config = await configurationService.get(this.chartComponentId)
      if (config) {
        // actividades
        if (this.chartContainerModel.filters[0].data.length > 0) {
          if (config.data.activities && config.data.activities.length > 0) {
            config.data.activities.forEach(activity => {
              if (this.chartContainerModel.filters[0].data.find(a => a.id === activity.id) && !this.chartContainerModel.filters[0].selectedData.find(a => a.id === activity.id)) {
                this.chartContainerModel.filters[0].selectedData.push(activity)
              }
            })
          }

          await this.activityFilterCallback()
          if (config.data.types && config.data.types.length > 0) {
            config.data.types.forEach(type => {
              if (this.chartContainerModel.filters[1].data.find(t => t.id === type.id) && !this.chartContainerModel.filters[1].selectedData.find(t => t.id === type.id)) {
                this.chartContainerModel.filters[1].selectedData.push(type)
              }
            })
          }
        } else {
          initialLoad = false
        }
        // rango de tiempo
        if (config.data.selectedDateAndTimeRange && this.chartContainerModel.dateAndTimeRanges.find(range => range.id === config.data.selectedDateAndTimeRange)) {
          this.chartContainerModel.selectedDateAndTimeRange = config.data.selectedDateAndTimeRange
          // custom
          if (config.data.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
            if (config.data.selectedDateAndTimeRangeCustomType &&
              config.data.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
              this.chartContainerModel.selectedDateAndTimeRangeCustomType = config.data.selectedDateAndTimeRangeCustomType
              if (config.data.sinceDate && config.data.toDate) {
                this.chartContainerModel.sinceDate = config.data.sinceDate
                this.chartContainerModel.toDate = config.data.toDate
              } else {
                initialLoad = false
              }
            } else if (config.data.selectedDateAndTimeRangeCustomType &&
              config.data.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE_AND_TIME) {
              this.chartContainerModel.selectedDateAndTimeRangeCustomType = config.data.selectedDateAndTimeRangeCustomType
              if (config.data.sinceDate && config.data.toDate && config.data.sinceTime && config.data.toTime) {
                this.chartContainerModel.sinceDate = config.data.sinceDate
                this.chartContainerModel.sinceTime = config.data.sinceTime
                this.chartContainerModel.toDate = config.data.toDate
                this.chartContainerModel.toTime = config.data.toTime
              } else {
                initialLoad = false
              }
            } else {
              initialLoad = false
            }
          }
        }
      } else { // CARGA SIN CONFIG
        // para selector CON actividad
        if (this.chartContainerModel.filters[0].data.length > 1) {
          await this.activityFilterCallback()
          // para selector SIN actividad
        } else if (this.chartContainerModel.filters[0].data.length === 1 && this.chartContainerModel.filters[0].data[0].key === 'NO_ACTIVITY') {
          this.chartContainerModel.filters[0].selectedData.cleanAndUpdate([this.chartContainerModel.filters[0].data[0]])
        } else {
          initialLoad = false
        }
      }
      return initialLoad
    }
  },
  watch: {
    'chartContainerModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.selectedDateAndTimeRange': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.selectedDateAndTimeRangeCustomType': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.sinceDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.sinceTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.toDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.toTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.selectorModel': {
      handler: function () {
        this.updateChart()
        this.selectorsModel[this.currentChartIndex] = this.chartContainerModel.selectorModel
      },
      deep: true
    },
    'chartContainerModel.percentageSwitchModel': {
      handler: function (val) {
        this.switchPercentage(val)
      },
      deep: true
    }
  }
}
