import UnitsInServiceComponent from '@/components/units-in-service/UnitsInServiceComponent.vue'
import UnitsStateComponent from '@/components/units-state/UnitsStateComponent.vue'
import WorkingAverageSpeedComponent from '@/components/working-average-speed/WorkingAverageSpeedComponent.vue'
import ProductiveTimeComponent from '@/components/productive-time/ProductiveTimeComponent.vue'
import WorkFrontsStateComponent from '@/components/work-fronts/WorkFrontsStateComponent.vue'
import TractorDischargeComponent from '@/components/tractor-discharge/TractorDischargeComponent.vue'
import EngineStateComponent from '@/components/engine-state/EngineStateComponent.vue'
import StoppedWorkFrontsComponent from '@/components/stopped-work-fronts/StoppedWorkFrontsComponent.vue'
// import { SECTOR_KEY } from '@colven/common-domain-lib/lib'

export default {
  name: 'HarvestDashboard',
  components: {
    UnitsInServiceComponent,
    UnitsStateComponent,
    WorkingAverageSpeedComponent,
    ProductiveTimeComponent,
    WorkFrontsStateComponent,
    TractorDischargeComponent,
    EngineStateComponent,
    StoppedWorkFrontsComponent
  },
  data: () => ({
    sector: 'HARVEST'
    // unitsInServiceSector: 'HARVEST',
    // unitsStateSector: 'HARVEST',
    // workingAverageSpeedSector: SECTOR_KEY.WORKING_AVERAGE_SPEED_HARVEST,
    // productiveTimeSector: SECTOR_KEY.WORKING_AVERAGE_SPEED_HARVEST,
    // workFrontsStateSector: SECTOR_KEY.WORK_FRONTS_HARVEST,
    // tractorDischargeSector: SECTOR_KEY.TRACTOR_DISCHARGE_HARVEST,
    // stoppedWorkFrontsSector: SECTOR_KEY.STOPPED_WORK_FRONTS,
    // engineStateSector: 'ENGINE_STATE_HARVEST'
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
  }
}
